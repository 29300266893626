<template>
    <Layout>
        <div style="display: flex;justify-content: center;padding:50px 0;align-items: center;width: 100%;">
            <div style="width:80%;" v-if="login_data.login_status === -1">

                <!-- 登录 -->
                <p style="text-align:center;padding:10px 0;">{{ $t("login.title") }}</p>
                <div style="padding-bottom:10px;">
                    <el-input v-model="login_data.mail" :placeholder="$t('input.placeholder.mail')" @blur="checkMailVerifyStatus">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{ $t("input.lable.mail") }}
                        </div>
                    </el-input>
                </div>
                <div v-show="login_data.verify_status === 1" style="padding-bottom:10px;">
                    <el-input v-model="login_data.verify_code" :placeholder="$t('input.placeholder.verify_code')">
                        <div slot="prepend">
                            <i class="el-icon-message" />
                            {{ $t("input.lable.verify_code") }}
                        </div>
                        <div v-if="login_data.send_status === 1" slot="append" style="cursor:pointer;">
                            <div style="width:100%;height:100%;" @click="sendCode('login_data')">
                                {{ $t("button.verify_code_send") }}
                            </div>
                        </div>
                        <div v-if="login_data.send_status === 2" slot="append">
                            <i class="el-icon-loading" />
                        </div>
                        <div v-if="login_data.send_status === 3" slot="append">
                            {{ login_data.send_text }}
                        </div>
                    </el-input>
                </div>

                <div style="padding-bottom:10px;">
                    <el-input v-model="login_data.password" :placeholder="$t('input.placeholder.password')" show-password>
                        <div slot="prepend">
                            <i class="el-icon-key" />
                            {{ $t("input.lable.password") }}
                        </div>
                    </el-input>
                </div>

                <!-- <div style="text-align:right;padding-bottom:10px;">
                    <router-link :to="{ name: 'password-reset' }">
                        {{ $t("button.forget_password") }}
                    </router-link>
                </div> -->

                <div style="padding-bottom:10px;">
                    <el-button type="warning" size="medium" style="width:100%;" @click="loginByMail">
                        {{ $t("button.login") }}
                    </el-button>
                </div>
            </div>
            <div style="width:80%;" v-if="login_data.login_status === 1">

                <!-- 登录 -->
                <p style="text-align:center;padding:10px 0;">{{ $t("common.in_login") }}</p>
                <p style="display: flex;justify-content: center;font-size: 30px;color: #e7a23b;">
                    {{ login_data.mail }}
                </p>

                <div style="padding:30px 0px;" >
                    <el-button type="warning" size="medium" style="width:100%;" @click="loginByTokenMail()">{{ $t('jin-ru-you-xiang') }}
                    </el-button>
                </div>

                <div style="text-align:center;">
                    <a @click="tabAcount" style="cursor: pointer;">
                        {{ $t('qie-huan-qi-ta-you-xiang') }}
                    </a>
                </div>

            </div>
        </div>
        <!-- breadcrumb-area-end -->
    </Layout>
</template>

<script>
import Cookies from 'js-cookie'
import config from '@/config/config.js'
import { GetMailDetailByTokenMail, LoginMailByMail, GetMailVerifyStatus } from '@/api/client'
import { sendCode } from '@/api/user'
import Layout from '@/components/Layout.vue'
import { CaptchaCode } from '@/utils/captcha'
import { JumpMailClient, Logout, InitApiURL } from '@/utils/client'
import { IsMail } from '@/utils/verify'
export default {
    components: { Layout },
    name: 'Login',
    data () {
        return {
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT,
            // 登录数据
            login_data: {
                mail: '',
                password: '',
                verify_code: '',
                send_text: this.$t('button.verify_code_send'),
                send_status: 1,
                send_product: 'login',
                login_status: -1,
                verify_status: -1
            },
            login_user_data: {
                nick_name: '',
                user_name: ''
            },
            mail_type: ''
        }
    },
    async mounted () {
        this.initClientMailInfo()
    },
    methods: {
        // 获取邮箱信息
        async initClientMailInfo () {
            const token = Cookies.get(config.COOKIES.TOKEN_MAIL) || ''
            if (token === '') return
            this.token = token
            const { rlspecauth = '' } = Cookies.get(config.COOKIES.RL_SPECAUTH) || {}
            if (rlspecauth === '') return

            try {
                const mailDetail = await GetMailDetailByTokenMail({
                    token_mail: token
                })
                const { mail = '', status = 0 } = mailDetail.data.data || {}
                if (status === 1) {
                    this.login_data.mail = mail
                    this.login_data.login_status = 1
                } else {
                    this.tabAcount()
                }
            } catch (err) {
                console.error('GetClientMailDetail err', err)
                this.$message.error(err || this.$t('huo-qu-deng-lu-zhuang-tai-shi-bai'))
            }
        },
        // 切换邮箱账号
        tabAcount () {
            Logout()
            this.login_data.login_status = -1
        },
        // 通过token登录
        async loginByTokenMail () {
            JumpMailClient([])
        },
        // 登录
        async loginByMail () {
            if (!IsMail(this.login_data.mail)) {
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }
            if (this.login_data.password === '') {
                this.$message.error(this.$t('input.verify.password_empty'))
                return
            }

            this.login_data.send_status = 2

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this.login_data.send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }
            const { captcha_id = 0, captcha_value = '' } = captchaData
            try {
                const loginResponse = await LoginMailByMail({
                    mail: this.login_data.mail,
                    verify_code: this.login_data.verify_code,
                    password: this.login_data.password,
                    captcha_id,
                    captcha_value,
                    product: this.login_data.send_product
                })
                const { login_info = {}, api = {} } = loginResponse.data || {}

                // API地址
                const { api_url = '', mail_type = '' } = api || {}
                if (api_url === '' || (mail_type !== 'domain_mail' && mail_type !== 'random_mail')) {
                    throw new Error(this.$t('wu-fa-huo-qu-qing-qiu-di-zhi'))
                }
                InitApiURL(mail_type, api_url)

                // 登录信息
                const { token_mail = '', token_rl = {} } = login_info || {}
                if (token_mail === '') {
                    throw new Error(this.$t('deng-lu-shi-bai-token-wei-kong'))
                }
                this.$message.success(this.$t('message.login_success'))
                Cookies.set(config.COOKIES.TOKEN_MAIL, token_mail, { expires: 7 })
                JumpMailClient(token_rl)
            } catch (err) {
                console.error('login Err', err)
                this.$message.error(err || this.$t('message.login_fail'))
            }
        },
        // 获取是否二次验证
        async checkMailVerifyStatus () {
            if (!IsMail(this.login_data.mail)) {
                return
            }
            try {
                const checkResponse = await GetMailVerifyStatus({
                    mail: this.login_data.mail
                })
                const { verify_status = 1 } = checkResponse.data || {}
                if (verify_status === 1) { this.login_data.verify_status = 1 }
            } catch (err) {
                console.error('GetMailVerifyStatus err', err)
                this.$message.error(err || this.$t('yan-zheng-shi-bai'))
            }
        },
        // 倒计时
        lastTime (data) {
            let time = 60
            const lastInterval = setInterval(() => {
                this[data].send_status = 3
                if (time <= 0) {
                    clearInterval(lastInterval)
                    this[data].send_status = 1
                    this[data].send_text = this.$t('button.verify_code_send')
                    return
                }
                time--
                this[data].send_text = `${time}s`
            }, 1000)
        },

        // 发送验证码
        async sendCode (data) {
            this[data].send_status = 2
            if (!IsMail(this[data].mail)) {
                this[data].send_status = 1
                this.$message.error(this.$t('input.verify.mail_format'))
                return
            }

            // 验证码
            let captchaData = {}
            try {
                captchaData = await CaptchaCode()
            } catch (err) {
                console.error('CaptchaCode Err:' + err)
                this[data].send_status = 1
                this.$message.error(err || this.$t('message.verify_code_fail'))
                throw err
            }

            const { captcha_id, captcha_value } = captchaData || {}

            try {
                await sendCode({
                    captcha_id,
                    captcha_value,
                    tel_num: this[data].tel_num,
                    mail: this[data].mail,
                    product: this[data].send_product
                })
                this.lastTime(data)
            } catch (err) {
                this[data].send_status = 1
                console.error('getUserInfo err', err)
                this.$message.error(err || this.$t('message.send_code_fail'))
            }
        }
    }
}
</script>

<style scoped>
.container {
    position: relative;
    width: 100%;
    margin: 20px auto 0 auto;
}

.wrapper {
    text-align: center;
    width: 100%;
}

.wrapper .word {
    color: #303133;
    font-size: 14px;
}

.wrapper .word:before,
.wrapper .word:after {
    position: absolute;
    top: 50%;
    width: 30%;
    height: 1px;
    background: #DCDFE6;
    content: '';
}

/* 调整背景横线的左右距离 */
.wrapper .word::before {
    left: 0;
}

.wrapper .word::after {
    right: 0;
}
</style>
