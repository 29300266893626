import { GetCaptcha } from '@/api/captcha'
import config from '@/config/config.js'
import ElementUI from 'element-ui'
import i18n from '@/lang/index.js'
// 输入验证
export async function CaptchaCode () {
    try {
        const captchaInfo = await GetCaptcha({})
        const { captcha_id = '', image_url = '' } = captchaInfo.data || {}
        if (captcha_id === '' || image_url === '') {
            throw new Error(i18n.t('huo-qu-yan-zheng-ma-shi-bai'))
        }

        const apiUrl = config.API_URL
        const imgURI = apiUrl + '/v1' + image_url
        const newElement = document.createElement('style')
        newElement.innerHTML = ('.weiqu-captcha-alert{width: 320px;}')
        document.body.appendChild(newElement)
        window.WeiquChangeCaptchaImg = function () {
            document.getElementById('weiqu_captcha_img_url').setAttribute('src', imgURI + '?reload=true')
        }
        const result = await ElementUI.MessageBox.prompt(`<img src="${imgURI}" id="weiqu_captcha_img_url"><span id="weiqu_captcha_img_change" onclick="WeiquChangeCaptchaImg()" style="color:#2483ff;cursor: pointer;">${i18n.t('huan-yi-ge')}</span>`, i18n.t('qing-shu-ru-yan-zheng-ma'), {
            dangerouslyUseHTMLString: true,
            customClass: 'weiqu-captcha-alert',
            confirmButtonText: i18n.t('yan-zheng-0'),
            showCancelButton: false,
            closeOnClickModal: false
        })
        return { captcha_id, captcha_value: result.value || '' }
    } catch (err) {
        console.log('CaptchaCode Err:' + err)
        throw err
    }
}
