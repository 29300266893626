<template>
    <div class="bg" :style="{ backgroundImage: 'url(' + oem_bg + ')' }">
        <el-row :gutter="0" justify="center" align="middle" type="flex" class="container">
            <el-col :xs="0" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="left">
                    <h2>{{ oem.oem_title||VUE_APP_TITLE }}</h2>
                    <p>{{oem.oem_desc || $t('layout.title_child')}}</p>
                </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="right" style="position: relative;">
                    <!-- <div style="position: absolute;right: 20px;top: 20px;color: #909399;font-size: 10px;text-decoration: underline;cursor: pointer;" @click="tabCorp" v-if="VUE_APP_PRODUCT === 'cuiqiu_domain_pri'">切换主体</div> -->
                    <slot/>
                </div>
            </el-col>
        </el-row>
        <div class="bottom" v-if="VUE_APP_PROJECT === 'cuiqiu' && oemModel===-1">
            <!-- <p><a href="https://mail.cuiqiu.com" >脆球邮箱提供技术支持</a></p> -->
            <p style="text-align: center;"><span>Copyright © 微趣科技 2015~2024 </span> <a rel="external nofollow" href="https://wenjuan.feishu.cn/m?t=sQBeWG10HrHi-wfxm" target="_blank">违法举报</a> <span style="margin: 0px 5px;">增值电信业务许可证：津B2-20220354</span> <a rel="external nofollow" href="https://beian.miit.gov.cn" target="_blank">津ICP备18003254号</a> <a rel="external nofollow" target="_blank" href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&amp;serial=91120224MA06AALQ08-SAIC_SHOW_10000091120224MA06AALQ081668221424719&amp;signData=MEQCID0DYPEkKrRm8f0bD5+s/jWKxTGN/P5EjZeQmL/RCbxPAiBZlnw1IZuhOxy2oNCCHhoDpIYnJlOSKSFhCX54TYmnjQ=="><img data-v-300f8e7f="" src="@/assets/gongshang.png" rel="external nofollow" style="vertical-align: middle; height: 16px; width: 16px;"> 营业执照</a> <a rel="external nofollow" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011502000166"><img src="@/assets/beian.png" rel="external nofollow" style="vertical-align: middle; height: 16px; width: 16px;"> 津公网安备12011502000166号</a></p>
            <p style="text-align: center;">
                禁止将脆球邮箱产品用于诈骗、赌博、色情、木马、病毒等违法业务，本公司将对违规使用者封停账号、停止服务，并上报至网安部门。
            </p>
        </div>

        <whatsapp-button v-if="VUE_APP_PROJECT === 'getmx' && oemModel===-1" />

        <!-- <weixin-button v-if="VUE_APP_PROJECT === 'cuiqiu' && oemModel===-1" /> -->
    </div>

</template>

<script>
import { mapState } from 'vuex'
import whatsappButton from '@/components/whatsapp-button/whatsapp-button'
// import weixinButton from '@/components/weixin-button/weixin-button'
export default {
    name: 'Layout',
    props: {
        msg: String
    },
    components: {
        whatsappButton
        // weixinButton
    },
    computed: {
        ...mapState({
            oem: state => state.oem,
            oemModel: state => state.oemModel
        }),
        oem_bg () {
            return this.oem && this.oem.oem_bg && this.oem.oem_bg !== '' ? this.oem.oem_bg : require('../assets/bg.jpg')
        }
    },
    data () {
        return {
            VUE_APP_TITLE: process.env.VUE_APP_TITLE,
            VUE_APP_PROJECT: process.env.VUE_APP_PROJECT,
            VUE_APP_PRODUCT: process.env.VUE_APP_PRODUCT
        }
    },
    mounted () {
    },
    methods: {
    }
}
</script>

<style scoped>
.bg{
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat:no-repeat;
    background-attachment:fixed;
}

.container{
    height: 100vh;
}
.left{
    background: rgba(255,255,255,0.4);
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.right{
    background: #fff;
    height: 380px;
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bottom{
    font-weight: 400;
    position: fixed;
    bottom: 40px;
    text-align: center;
    width: 100%;
    height: 30px;
    left: 0;
    font-size: 14px;
    color: #717f99;
}
</style>
