// 输入验证
export function IsMail (mail) {
    // eslint-disable-next-line no-useless-escape
    return /^[A-Za-z0-9\._-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(mail)
}

export function IsTel (tel) {
    if (tel.length !== 11) {
        return false
    }
    return true
}
